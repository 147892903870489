import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull, ImageCenter } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'

const characters = [
  {name: 'Baron Scarpia', voice: 'Baritone', desc: 'Officially the Chief of Police. A despicable chap whichever way you look at it.'},
  {name: 'Cesare Angelotti', voice: 'Bass', desc: 'A republican fugitive who having escaped prison seeks sanctuary in the church. Cavaradossi then hides him down a well. The only character to die off-stage.'},
  {name: 'Floria Tosca', voice: 'Soprano', desc: 'A famous singer in the stereotypical Diva mode. True of heart but relentlessly jealous.'},
  {name: 'Mario Cavaradossi', voice: 'Tenor', desc: 'Painter by trade and the lover of Tosca. A republican to a fault who Scarpia does not well like.'},
  {name: 'Spoletta', voice: 'Tenor', desc: 'Seriously creepy fellow. Largely a one-dimensional lackey to Scarpia.'},
  {name: 'The Sacristan', voice: 'Bass', desc: 'A sweet, bumbling priest who gets his facts badly wrong in Act I.'},
];

const inbrief = {name: 'Tosca', composer: "Giacomo Puccini", librettist: "Luigi Illica and Giuseppe Giacosa", language: "Italian", date: 'Jan 14th, 1900', acts: "Three", musiclength: "One hours, fifty five minutes"};

const bibliography = [
  {name: 'Tosca\'s Rome: The Play and the Opera in Historical Perspective', writer: 'Susan Vandiver Nicassio', url: 'https://amzn.to/3js3hrJ'},
  {name: 'Puccini Without Excuses: A Refreshing Reassessment of the World\'s Most Popular Composer', writer: 'William Berger', url: 'https://amzn.to/2gt5h3j'},
  {name: 'The Complete Operas Of Puccini', writer: 'Charles Osborne', url: 'https://amzn.to/2WxFpuq'},
];

// markup
const ToscaPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tosca | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/tosca" />
          <meta name="description" content="A guide to Giacomo Puccini's incredible opera Tosca. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>

        <Hero title="Tosca" subtitle="An Opera by Giacomo Puccini"/>
        <MainBodySection>

          <ImageRight>
            <StaticImage src="../../images/operas/tosca/tosca_candleposter.jpeg" alt="Adolf Hohenstein Poster for Tosca"/>
            <figcaption>Adolf Hohenstein Poster for Tosca</figcaption>
          </ImageRight>

          <p><em>Tosca</em> is one of the most lethal of operas. None of the central characters makes it to the end alive, hero or villain. Unsurprisingly then, it's a thrilling melodrama often dismissed as one-dimensional and tawdry. However, you’d be foolish to go in with this attitude; Puccini takes an overtly theatrical tale and makes it astonishingly moving.</p>

          <p><em>Tosca</em> is fundamentally historical fiction, a vague knowledge of the period is beneficial,
              though it is very much the 18th Century as seen through 19th Century eyes. Luckily it still works as
              seen through 21st Century eyes because at its core, <em>Tosca</em> is a tale of romance over politics;
              featuring a heroic painter, a despicable ruler and an opera superstar, Tosca herself!</p>

          <p>Productions of Tosca can go a variety of ways though predominantly, it remains an opera staged fairly literally. Expect a big church to open the evening and an impressive battlement to close it.</p>

          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-103"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 45 mins</SynopsisAct>

            <p>We begin in Sant'Andrea della Valle, a large church in Rome. Angelotti comes pelting in. He has just escaped from prison and hides in a small, private chapel within the church. Our hero for the evening,
                Cavaradossi enters to resume his work. He is a painter and has been commissioned to paint a picture of
                Mary Magdalene for the church. The sacristan bumbles about and offers Cavaradossi some food, which he refuses. Cavaradossi gets his first big aria of the evening, “Recondita Armonia”.</p>
          
            <VideoPlayer src="https://www.youtube.com/embed/9jEDfNbmfds?autoplay=1" id="9jEDfNbmfds" title="Piotr Beczala sings 'Recondita Armonia'"/>

            <p>Angelotti bursts out, revealing himself to Cavaradossi. They are old friends, but Cavaradossi is on the brink of assisting Angelotti, when who should show up but Tosca herself. Cavaradossi quickly gives Angelotti
                his food and hides him away again.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <p>The jealous Tosca is convinced she overheard Cavaradossi talking to someone, believing it to be another woman. She sees Cavaradossi's new painting, recognizing the image as that of Marchesa Attavanti and turns jealous again. Cavaradossi calms her down with “Qual’occhio al mondo”, what eyes in the world
                could compare to hers!</p>

            <ImageFull>
              <StaticImage src="../../images/operas/tosca/ToscaPainting_KenHoward_Met.jpg" alt="Tosca (Karita Matilla) and Cavaradossi (Marcelo Álvarez), Met Opera"/>
              <figcaption>Tosca (Karita Matilla) and Cavaradossi (Marcelo Álvarez), Met Opera</figcaption>
            </ImageFull>

            <p>She leaves, still demanding that he change the eyes, and Angelotti reappears. Cavaradossi shows him a
                secret way out and gives him a key to his villa, informing him of a hiding place in the well of the garden. Cannon fire is heard, announcing the escape of a prisoner (Angelotti). Angelotti quickly flees.</p>

            <p>The sacristan returns with the church choristers, celebrating the news that Napoleon has been defeated. However, things quickly turn sour as Scarpia arrives with his lackeys. They believe Angelotti is hiding in the church. They search and find the empty food basket and a fan belonging to Attavanti.
                Scarpia interrogates the sacristan and, learning Cavaradossi has been here, becomes convinced that he is on
                the right track.</p>

            <p>Tosca comes back looking for Cavaradossi. Scarpia connives to make her jealous by showing her the
                Attavanti fan. He succeeds, and Tosca leaves to confront Cavaradossi, unaware that Scarpia’s men will follow her. A truly epic scene of gloating is delivered by Scarpia, culminating in the magnificent,
                “Te Deum”.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 40 mins</SynopsisAct>

            <p>It is later the same day, and the curtain rises on Scarpia’s apartment. He has been unable to find Angelotti but has arrested Cavaradossi and sent a note for Tosca to come to his apartment. Cavaradossi is dragged in and interrogated. He reveals nothing, and as Tosca arrives, he is taken away to be tortured. In his parting
                words he tells Tosca to tell Scarpia nothing, no matter his suffering.</p>

            <p>Tosca initially resists Scarpia, but after hearing Cavaradossi’s screams of pain, she crumbles and reveals the location of Angelotti. Cavaradossi is dragged back in, and upon hearing that Tosca has broken, he is devastated. Sciaronne arrives with the news that Napoleon has been victorious at Marengo.
                This is excellent news for Cavaradossi, and he unleashes “Vittoria, vittoria” in celebration before being dragged off to be executed.</p>

            <AudioSet audiosrc="/audio/butterfly/Vittoria_Pavarotti.mp3" desc="'Vittoria, vittoria' sung by Luciano Pavarotti"/>

            <p>Left alone with Tosca, Scarpia presents her with a hideous bargain. If she will give herself to him,
                Cavaradossi will be released. She rejects his advances and sings one of the most famous tunes in the opera,
                “Vissi d’arte” - I lived for art.</p>

            <VideoPlayer src="https://www.youtube.com/embed/3pryZos2oOk?autoplay=1" id="3pryZos2oOk" title="Sondra Radvanovsky sings 'Vissi d'arte'"/>

            <p>Spoletta enters with the news that Angelotti was found but killed himself before he could be arrested.
                Furthermore, they have arranged the execution of Cavaradossi. Hearing all this, the now broken Tosca agrees to Scarpia’s deal. Scarpia tells Spoletta to complete the execution in the same manner as the
                execution of Count Palmieri.</p>

            <p>Tosca presses Scarpia to additionally grant her and Cavaradossi safe passage from Rome the next day. He agrees, and while writing out the letter... Tosca finds a knife on the dinner table. As Scarpia moves to rape her, she stabs him to death.</p>

            <p>Tosca takes the letter from him, lights candles and places a crucifix on him as a gesture of piety before fleeing the scene.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/tosca/ToscaCandel_CatherineAshmore_ROH.jpg" alt="Tosca (Martina Serafin) lays candles, Royal Opera"/>
              <figcaption>Tosca (Martina Serafin) lays candles, Royal Opera</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III - Running Time: 30 mins</SynopsisAct>

            <p>The battlement of the Castel Sant’Angelo early the next morning. A young boy is heard singing as the bells chime for matins. Cavaradossi is led in and informed this will be his final hour. He has no interest in seeing a priest but asks for some paper to write a letter to Tosca. He sings “E lucevan le
                stelle” - and the stars shone.</p>

            <p>Tosca arrives and quickly explains everything. They will fake the execution. The firing squad will use blanks so Cavaradossi must pretend to die. They joyously sing of the life they can now have together.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/tosca/Shooting_CatherineAshmore_ROH.jpg" alt="Tosca (Angela Gheorghiu) watches Cavaradossi shot, Royal Opera"/>
              <figcaption>Tosca (Angela Gheorghiu) watches Cavaradossi shot, Royal Opera</figcaption>
            </ImageFull>

            <p>Cavaradossi is led away to be shot. The marksmen fire, Cavaradossi falls to the ground. The marksmen depart
                as Tosca runs over to Cavaradossi. Surprise, surprise! She finds him dead. Scarpia had tricked her all along. Spoletta is heard off stage with soldiers, Scarpia’s body has been found!</p>

            <p>Tosca runs to the parapet and crying “O Scarpia, Avanti a Dio!” - Oh Scarpia, we meet before God! She flings herself over the edge.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/tosca/ToscaFalls_MikeHoban_ENO.jpg" alt="Tosca (Amanda Echalaz) falls, English National Opera"/>
              <figcaption>Tosca (Amanda Echalaz) falls, English National Opera</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where in the World"/>

            <h3>Tosca takes place entirely within the city of Rome in 1800. Each Act takes place in precise, real locations in Rome.</h3>

            <ImageFull>
              <StaticImage src="../../images/operas/tosca/SantA.jpg" alt="Sant' Andrea della Valle"/>
              <figcaption>Act I takes place within the church of Sant'Andrea della Valle. This is a photo of the the interior &copy; Claudio Di Ludovico</figcaption>
            </ImageFull>

            <ImageFull>
              <StaticImage src="../../images/operas/tosca/Palazzo.jpg" alt="The Parlazzo Farnese"/>
              <figcaption>Act II takes place within Scarpia's apartment located in the Palazzo Farnese &copy; <a href="http://www.flickr.com/photos/ziopaopao/3267969772/">zio
                    Paolino</a></figcaption>
            </ImageFull>


            <ImageFull>
              <StaticImage src="../../images/operas/tosca/CastelAngelo.jpg" alt="Castel Sant' Angelo in Rome"/>
              <figcaption>Act III occurs on the Battlements of the Castel Sant' Angelo, here seen at night &copy; <a
                        href="http://www.flickr.com/photos/lorenzoferrara/2718433415/">Lorenzo Ferrara</a></figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="History"/>

            <ImageRight>
              <StaticImage src="../../images/operas/tosca/Sardou_Puccini.jpeg" alt="Sardou and Puccini"/>
              <figcaption>Sardou and Puccini</figcaption>
            </ImageRight>

            <p>Puccini's Tosca had the longest gestation period of any of his operas. Victorien Sardou's drama La Tosca
                was first suggested as a possible subject in 1889, but Puccini wrote and premiered two other operas
                (<em>Manon Lescaut</em> and <Link to='/operas/boheme'>La bohème</Link>) before settling his energies on <em>Tosca</em>, which finally premiered in January 1900. The opera likely would never have been written, at least not by
                Puccini, if it weren't for the esteem of a respected elder and the jealousy inspired by a contemporary.
            </p>

            <p>In 1894, Alberto Franchetti was already working on an opera based on Sardou's play, and his libretto was being written by Luigi Illica, who was also working on Puccini's <Link to='/operas/boheme'>La bohème</Link> at the time. One day in Paris while Sardou was meeting with Illica to discuss what he had written so far, Giuseppe Verdi -
                the most successful operatic composer in the world - stopped in. Verdi, who appreciated Sardou's work but in his 80s was too old to take on a new project, was deeply moved by what Illica had written. Once
                Puccini, having heard about this meeting, he had to have the opera, so Illica and another mutual friend went to
                Franchetti and sandbagged him into giving up the opera.</p>

            <p>The premiere took place in Rome at the Teatro Costanzi. Despite rumours of a possible bomb threat because of the current political unrest, it went off without incident. It was well-received by the public, although less so by the critics.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-109"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Popular?</FunFactsHeader>

            <p><em>Tosca</em> has been wildly popular since it's premiere and was the 5th most performed opera globally over the last decade <a
                        href="http://www.operabase.com/top.cgi?lang=en&break=0&show=opera&no=10&nat=" target="_new">Operabase</a>.
            </p>

            <FunFactsHeader>Exit with...</FunFactsHeader>

            <p>The instruction "exit with the principals" doesn't fully work in the case of Tosca. In older times
                when opera productions were sometimes only minimally rehearsed, a director might have said such a thing to the supernumeraries. The, no doubt apocryphal, story goes that the soldiers in the final Act of Tosca were given just this instruction and as Tosca leapt over the battlements -- they all went over with her!</p>

            <p>Another popular myth relating to Tosca's suicide, and this seems a more likely tale, is the placement of a trampoline rather than a mattress for extra safety for the falling Soprano. The audience as a
                result got not only Tosca's initial fall but also a series of successive appearances and
                disappearances behind the battlements...</p>

            <FunFactsHeader>Well-made?</FunFactsHeader>

            <ImageRight maxwidth="95px">
              <StaticImage src="../../images/operas/tosca/Victorien_Sardou_in_1901.jpg" alt="Victorien Sardou"/>
              <figcaption>Victorien Sardou</figcaption>
            </ImageRight>

            <p>La Tosca by Sardou is a “well-made play”. This term isn’t a mark of quality but a specific form of drama that conforms to certain criteria. An artificial, mechanical method of playwrighting that requires a slow, suspenseful build-up to a climactic scene when all the plot threads become resolved.
                The plot is usually hinged on props rather than people; letters are a popular option. If this all sounds a
                bit ridiculous, then consider that Sardou was by no means the only one writing these, it was the
                dominant form for much of the 20th Century, though constantly scorned and Henrik Ibsen and Anton
                Chekhov, amongst many others, were highly influenced by it. Wilkie Collins summed it up as, “Make ’em laugh, make ’em weep, make ’em wait.” which didn’t stop him using many aspects of the well-made play in his work.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-110"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>Live</FunFactsHeader>

            <p>Sardou specified that his story took place from 17 June 1800 to dawn the following morning, and while Puccini's libretto states June 1800, though he likely intended the same specificity. Because of that detail and the fact that each Act takes place in a single location in Rome, and these locations still exist, it was perhaps inevitable that someone would try to film the opera at the correct times and places. This occurred in 1992 with Catherine Maliftano in the title role.</p>

            <p>Another version of less interest, but deserving of mention, maybe just because of its cast, is the
                2001 <em>Tosca</em> directed by the French filmmaker Benoit Jacquot. Angela Gheorghiu and Roberto
                Alagna star as Tosca and Cavaradossi, but while the film presents the opera in a linear fashion, it does so using video of a studio session interspersed with performance footage from a soundstage and handheld video shots from Rome. Overall the actual opera loses much of its visual power.</p>

            <FunFactsHeader>Sarah Bernhardt</FunFactsHeader>

            <ImageCenter>
              <StaticImage src="../../images/operas/tosca/SarahBernhardt_Tosca.jpg" alt="Sarah Bernhardt as Tosca"/>
              <figcaption>Sarah Bernhardt as Tosca</figcaption>
            </ImageCenter>

            <p>Puccini saw the legendary French actress Sarah Bernhardt play Floria Tosca in Sardou’s drama La Tosca twice. The first time in 1890 at Milan’s Teatro dei Filodrammatici and the second in 1895 in Florence. Despite speaking no French, he was enamoured of the performance (although less so the second time, according to biographers). The play was written as a showcase for Bernhardt, and it was she who initially wore the silk dress and plumed hat and carried a cane and bouquet that have become standard fare for second Act Toscas on their way to the cathedral.</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default ToscaPage
